import React from "react";
import NavigationMenu from "./src/common/NavigationMenu";
import Footer from "./src/common/Footer";
import { MainProvider } from "./src/common/MainContext";

export default function Wrapper({ element }) {
  return (
    <MainProvider value={{ something: "add context here" }}>
      <NavigationMenu />
      <main>{element}</main>
      <Footer />
    </MainProvider>
  );
}
