import * as React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ButtonCall from '../components/ButtonCall';
import routes from '../common/routes';
import { useServices } from '../hooks/useServices';
import { useLocations } from '../hooks/useLocations';
import getServiceRoute from '../lib/routing/getServiceRoute';
import getLocationRoute from '../lib/routing/getLocationRoute';


export default function Footer() {
	const services = useServices();
	const locations = useLocations();
	const { file } = useStaticQuery(graphql`
	{
		file(relativePath: { eq: "Logo.png" }) {
			childImageSharp {
				fixed(width: 125, height: 125) {
					...GatsbyImageSharpFixed
				}
			}
		}
	}
`);
	// const Year = getFullYear();

	return (
		<footer className="py-8 bg-blue-gray lg:pt-20">
			<div className="container">
				<div className="flex flex-wrap">
					<div className="w-full pr-4 md:w-1/2 lg:w-1/4">
						<div className="mb-4">
							<Link to="/">
								<Img fixed={file.childImageSharp.fixed} />
							</Link>
						</div>
						<p>Get a quote for your foundation repair.</p>
						<ButtonCall />
					</div>
					<div className="w-full md:w-1/2 lg:w-1/4">
						<h3 className="mt-2 mb-4">
							<Link to="services" className="hover:text-primary-dark">
								Services
							</Link>
						</h3>
						<ul className="list-none">
							{services.map(({ node }) => (
								<li className="mb-1" key={node.frontmatter.name}>
									<Link className="hover:text-primary-dark hover:underline" to={getServiceRoute(node.frontmatter.name)}>
										{node.frontmatter.name}
									</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="w-full lg:w-1/2">
						<h3 className="mt-2 mb-4">
							<Link to={routes.LOCATIONS} className="hover:text-primary-dark">
								Locations
							</Link>
						</h3>
						<div className="flex flex-wrap -mx-2">
							<div className="w-full px-2 md:w-1/3">
								<ul className="list-none">
									{locations.slice(0, 17).map(({ node }) => (
										<li key={node.frontmatter.name}>
											<Link className="hover:text-primary-dark" to={getLocationRoute(node.frontmatter.name)}>
												{node.frontmatter.name}
											</Link>
										</li>
									))}
								</ul>
							</div>
							<div className="w-full px-2 md:w-1/3">
								<ul className="list-none">
									{locations.slice(18, 35).map(({ node }) => (
										<li key={node.frontmatter.name}>
											<Link className="hover:text-primary-dark" to={getLocationRoute(node.frontmatter.name)}>
												{node.frontmatter.name}
											</Link>
										</li>
									))}
								</ul>
							</div>
							<div className="w-full px-2 md:w-1/3">
								<ul className="list-none">
									{locations.slice(36, 100).map(({ node }) => (
										<li key={node.frontmatter.name}>
											<Link className="hover:text-primary-dark" to={getLocationRoute(node.frontmatter.name)}>
												{node.frontmatter.name}
											</Link>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
					<div className="w-full pt-12">
						<div className="flex flex-wrap">
							<div className="flex-initial w-full md:w-auto md:flex-shrink"><p>Copyright © {(new Date().getFullYear())} Foundation Experts</p></div>
							<div className="flex-initial w-full md:w-auto md:flex-grow md:text-right"><p>Privacy Policy</p></div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
