import { useStaticQuery, graphql } from "gatsby";

export interface ILocation {
  node: {
    id: string
    excerpt: string
    frontmatter: {
      image: string
      name: string
      shortDescription: string
      title: string
      youtubeId: string
    }
  }
}
export const useLocations = (): ILocation[] => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      {
        allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/cities/" } }) {
          edges {
            node {
              id
              excerpt(format: PLAIN, pruneLength: 120)
              wordCount {
                words
              }
              frontmatter {
                image
                name
                shortDescription
                title
                youtubeId
              }
              timeToRead
            }
          }
        }
      }
    `
  );

  const locations = allMarkdownRemark?.edges || [];

  if (locations.length === 0) {
    throw new Error("There were no locations returned");
  }

  return locations;
};
