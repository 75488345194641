import { useStaticQuery, graphql } from "gatsby";

export interface IService {
  node:{
    id: string;
    excerpt: string;
    wordCount: {
      words: number
    }
    frontmatter: {
      image: string;
      name: string;
      shortDescription: string;
      title: string;
      youtubeId: string;
    }
    timeToRead: number
  }
}

export const useServices = (): IService[] => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      {
        allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/services/" } }) {
          edges {
            node {
              id
              excerpt(format: PLAIN, pruneLength: 120)
              wordCount {
                words
              }
              frontmatter {
                image
                name
                shortDescription
                title
                youtubeId
              }
              timeToRead
            }
          }
        }
      }
    `
  );

  const services = allMarkdownRemark?.edges || [];

  if (services.length === 0) {
    throw new Error("There were no services returned");
  }

  return services;
};
