import * as React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import routes from './routes';
import ButtonCall from '../components/ButtonCall';

export default function NavigationMenu() {
	const { file } = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "Logo.png" }) {
				childImageSharp {
					fixed(width: 125, height: 125) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return (
		<nav className="">
			<div className="container py-2 lg:py-4">
				<div className="flex flex-wrap items-center">
					<div className="flex-grow w-auto">
						<Link to="/">
							<Img fixed={file.childImageSharp.fixed} />
						</Link>
					</div>

					<div className="flex items-center flex-shrink w-auto lg:flex-grow lg:w-3/4 justify-items-end">
						<div className="flex-grow pr-4 text-right">
							<Link className="flex-initial px-4 uppercase h4 hover:text-primary" to={routes.Locations}>
								LOCATIONS
							</Link>
							<Link className="flex-initial px-4 uppercase h4 hover:text-primary" to={routes.Services}>
								SERVICES
							</Link>
						</div>
						<div className="flex-shrink">
							<ButtonCall />
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}
