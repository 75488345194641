import * as React from "react";

type MainContextType = {};

export const MainContext = React.createContext({} as MainContextType);

export const useMainContext = (): MainContextType => {
  return React.useContext(MainContext);
};

export const MainProvider = MainContext.Provider;
